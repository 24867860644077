import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
    // meta: {
    //   allowInsecure: true
    // }
  },
  {
    path: "/studies",
    name: "studies",
    component: () => import(/* webpackChunkName: "studies" */ "../views/Studies.vue")
  },
  {
    path: "/projects",
    name: "projects",
    component: () => import(/* webpackChunkName: "projects" */ "../views/Projects.vue")
  },
  {
    path: "/project-templates",
    name: "projectTemplates",
    component: () => import(/* webpackChunkName: "projectTemplates" */ "../views/ProjectTemplates.vue")
  },
  {
    path: "/project-templates/:projectTemplateId",
    name: "projectTemplate",
    component: () => import(/* webpackChunkName: "projectTemplate" */ "../views/ProjectTemplate.vue")
  },
  {
    path: "/study-templates",
    name: "studyTemplates",
    component: () => import(/* webpackChunkName: "studies" */ "../views/StudyTemplates.vue")
  },
  {
    path: "/study-templates/:studyTemplateId",
    name: "studyTemplate",
    component: () => import(/* webpackChunkName: "studies" */ "../views/StudyTemplate.vue")
  },
  {
    path: "/auth-failed",
    name: "authFailed",
    component: () => import(/* webpackChunkName: "authFailed" */ "../views/AuthFailed.vue"),
    meta: {
      allowInsecure: true
    }
  },
  {
    path: "/auth-region-denied",
    name: "authRegionDenied",
    component: () => import(/* webpackChunkName: "authRegionDenied" */ "../views/AuthRegionDenied.vue"),
    meta: {
      allowInsecure: true
    }
  },
  {
    path: "/study/:studyId",
    name: "study",
    component: () => import(/* webpackChunkName: "study" */ "../views/Study.vue")
  },
  {
    path: "/projects/:projectId",
    name: "projectDetails",
    component: () => import(/* webpackChunkName: "project" */ "../views/Project.vue")
  },
  {
    path: "/surveys",
    name: "surveys",
    component: () => import(/* webpackChunkName: "surveys" */ "../views/Surveys.vue")
  },
  {
    path: "/study/:studyId/invitations",
    name: "invitations",
    component: () => import(/* webpackChunkName: "invitations" */ "../views/Invitations.vue")
  },
  {
    path: "/study/:studyId/invitations/:invitationId",
    name: "invitation",
    component: () => import(/* webpackChunkName: "invitation" */ "../views/Invitation.vue")
  },
  {
    path: "/study/:studyId/campaigns",
    name: "campaigns",
    component: () => import(/* webpackChunkName: "campaigns" */ "../views/Campaigns.vue")
  },
  {
    path: "/study/:studyId/waves/:waveId/campaigns/:campaignId",
    name: "campaign",
    component: () => import(/* webpackChunkName: "campaign" */ "../views/Campaign.vue")
  },
  {
    path: "/invitation-queue",
    name: "invitationQueue",
    component: () => import(/* webpackChunkName: "invitationQueue" */ "../views/InvitationQueue.vue")
  },
  {
    path: "/study/:studyId/fieldwork",
    name: "fieldwork",
    component: () => import(/* webpackChunkName: "fieldwork" */ "../views/Fieldwork.vue")
  },
  {
    path: "/study/:studyId/fieldwork/:waveId",
    name: "fieldworkWave",
    component: () => import(/* webpackChunkName: "fieldwork" */ "../views/Fieldwork.vue")
  },
  {
    path: "/study/:studyId/documents",
    name: "htmlDocuments",
    component: () => import(/* webpackChunkName: "htmlDocuments" */ "../views/HtmlDocuments.vue")
  },
  {
    path: "/study/:studyId/documents/:documentId/languages/:languageId",
    name: "htmlDocument",
    component: () => import(/* webpackChunkName: "htmlDocument" */ "../views/HtmlDocument.vue")
  },
  {
    path: "/study/:studyId/quotaGroups",
    name: "studyQuotaGroups",
    component: () => import(/* webpackChunkName: "studyQuotaGroups" */ "../views/StudyQuotaGroups.vue")
  },
  {
    path: "/study/:studyId/quotaGroups/:quotaGroupId/quotas",
    name: "studyQuotas",
    component: () => import(/* webpackChunkName: "StudyQuotas" */ "../views/StudyQuotas.vue")
  },
  {
    path: "/study/:studyId/assets",
    name: "studyAssets",
    component: () => import(/* webpackChunkName: "studyAssets" */ "../views/StudyAssets.vue")
  },
  {
    path: "/assets/:collectionId?",
    name: "assetCollections",
    component: () => import(/* webpackChunkName: "assetCollections" */ "../views/AssetCollections.vue")
  },
  {
    path: "/image-questions/:questionId?",
    name: "imageQuestions",
    component: () => import(/* webpackChunkName: "imageQuestions" */ "../views/ImageQuestions.vue")
  },
  {
    path: "/projects/:projectId/studies/:studyId/waves/:waveId/outlier-review",
    name: "outlierReview",
    props: true,
    component: () => import(/* webpackChunkName: "outlierReview" */ "../views/OutlierReview.vue")
  },
  {
    path: "/study/:studyId/waves/:waveId",
    name: "wave",
    props: true,
    component: () => import(/* webpackChunkName: "wave" */ "../views/Wave.vue")
  },
  // {
  //   path: "/email-test",
  //   name: "emailTest",
  //   component: () => import(/* webpackChunkName: "emailTest" */ "../views/EmailTest.vue")
  // },
  // {
  //   path: "/study/:studyId/quota",
  //   name: "quota",
  //   component: () => import(/* webpackChunkName: "quota" */ "../views/Quota.vue")
  // },
  {
    path: "/study/:studyId/sample",
    name: "sampleFiles",
    component: () => import(/* webpackChunkName: "samplefiles" */ "../views/SampleFiles.vue")
  },
  {
    path: "/study/:studyId/waves/:waveId/sample/:sampleId",
    name: "sampleFile",
    component: () => import(/* webpackChunkName: "samplefile" */ "../views/SampleFile.vue")
  },
  {
    path: "/study/:studyId/waves",
    name: "waves",
    component: () => import(/* webpackChunkName: "waves" */ "../views/Waves.vue")
  },
  {
    path: "/curations",
    name: "curations",
    component: () => import(/* webpackChunkName: "curations" */ "../views/Curations.vue")
  },
  {
    path: "/feature-matrix",
    name: "featureMatrix",
    component: () => import(/* webpackChunkName: "curations" */ "../views/FeatureMatrix.vue")
  },
  // {
  //   path: "/study/:studyId/schedule",
  //   name: "schedule",
  //   component: () => import(/* webpackChunkName: "schedule" */ "../views/Schedule.vue")
  // },
  // {
  //   path: "/study/:studyId/sample/:sampleId/verify",
  //   name: "sampleVerification",
  //   component: () => import(/* webpackChunkName: "sampleVerification" */ "../views/SampleVerification.vue")
  // },
  // {
  //   path: "/study/:studyId/sample/:sampleId/explore",
  //   name: "sampleExplorer",
  //   component: () => import(/* webpackChunkName: "sampleFileExplorer" */ "../views/SampleFileExplorer.vue")
  // },

  // {
  //   path: "/study/:studyId/sample/:sampleId/invitation-queue",
  //   name: "sampleFileInvitationQueue",
  //   component: () => import(/* webpackChunkName: "sampleFileInvitationQueue" */ "../views/SampleFileInvitationQueue.vue")
  // },
  {
    path: "/study/:studyId/invitation-queue",
    name: "studyInvitationQueue",
    component: () => import(/* webpackChunkName: "studyInvitationQueue" */ "../views/StudyInvitationQueue.vue")
  },
  {
    path: "/email/domains",
    name: "domains",
    component: () => import(/* webpackChunkName: "domains" */ "../views/Domains.vue")
  },
  {
    path: "/study/:studyId/domains",
    name: "studyDomains",
    component: () => import(/* webpackChunkName: "studyDomains" */ "../views/StudyDomains.vue")
  }
];

export default routes;
